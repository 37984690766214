<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">

        <div class="va-card">
          <div class="row">
            <div class="col-lg-12">
              <div class="dash_card_body ">

                <div class="header_section">
                  <h4 class="menu_header">Pos Configs</h4>
                  <div class="">
                    <button class="btn btn-default custom-orange" data-toggle="modal" data-target=".create_config">
                      <i class="fa fa-plus"></i> New Pos Config
                    </button>
                  </div>
                </div>

                <!-- modal -->
                <div class="modal fade create_config" id="autoreply" role="dialog">
                  <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                      <div class="modal-header">
                        Edit Pos Configs
                      </div>
                      <form @submit.prevent="createPosConfig()">
                        <div class="modal-body">
                          <div class="form-group">
                            <div>
                              <label for="criteria">Name</label>
                              <select class="form-control" id="name" v-model="name" @blur="v$.name.$touch">
                                <option value="">--select pos system--</option>
                                <option value="micros">Micros</option>
                                <option value="gaap">Gaap</option>
                              </select>
                            </div>
                            
                            <div class="input_error" v-if="v$.name.$error">
                              <span v-if="v$.name.required.$invalid">Name is required</span>
                            </div>
                          </div>
                          <div class="form-group">
                            <div>
                              <label for="version">Version</label>
                              <div v-if="name == 'micros'">
                                <select class="form-control" id="" v-model="version">
                                  <option value="res">Res</option>
                                  <option value="sim1">Simphony 1</option>
                                  <option value="sim2">Simphony 2</option>
                                </select>
                              </div>
                              <div v-else>
                                <input type="text" class="form-control" v-model="version">
                              </div>
                            </div>
                              
                          </div>
                          <div class="form-group">
                            <div>
                              <label for="status">Status:</label>
                              <select name="" v-model="status" @blur="v$.status.$touch" class="form-control mt-3">
                                <option value="1"><strong><span class="active">Publish</span></strong> </option>
                                <option value="0"><strong><span class="disabled">Unpublish</span></strong></option>
                              </select>
                            </div>
                            <div class="input_error" v-if="v$.status.$error">
                              <span v-if="v$.status.required.$invalid">Status is required</span>
                            </div>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button type="button" id="create_close_btn" class="btn btn-grey" data-dismiss="modal">Close</button>
                          <button type="submit" class="btn btn-orange"><span v-if="creating"><i class="fa fa-spinner fa-spin "></i></span> Save</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

              </div>
              <hr>
            </div>
            <div class="col-lg-12">
              <div class="dash_card_body">
                <div class="table_section">
                  <table class="table  table-hover">
                    <thead>
                      <tr>
                        <th width="5px">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Version</th>
                        <th scope="col">Status</th>
                        <th scope="col">Date Updated</th>
                        <th scope="col">Action</th>
                        <th scope="col">ID</th>

                        
                      </tr>
                    </thead>
                    <tbody v-if="!loading">
                      <tr v-for="(config, mindex) in posConfigs" :key="mindex">
                        <td>{{parseInt(mindex)+1}}.</td>
                        <td>{{ config.name }}</td>
                        <td>{{ config.version ? config.version : 'N/A' }}</td>
                        <td>
                          <strong> <span :class="[{'active': config.status == 1},{'disabled': config.status == 0}]">{{ config.status == 1 ? "Published" : "Unpublished"}}</span></strong>
                        </td>
                        <td>{{formatDate(config.created_at)}}</td>
                        <td>
                          <button class="btn btn-purple btn-sm mr-1" data-toggle="modal" data-target=".edit_config" @click="editConfig(config)"><i class='fas fa-edit'></i></button>
                        </td>
                        <td>
                          {{ config.id }}
                        </td>
                        <div class="modal fade edit_config" id="autoreply" role="dialog">
                          <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                              <div class="modal-header">
                                Edit {{ config.name }}
                              </div>
                              <form @submit.prevent="createPosConfig(config.id)">
                                <div class="modal-body">
                                  <div class="form-group">
                                    <div>
                                      <label for="criteria">Name</label>
                                      <input type="text" disabled v-model="name" @blur="v$.name.$touch" class="form-control">

                                    </div>
                                    <div class="input_error" v-if="v$.name.$error">
                                      <span v-if="v$.name.required.$invalid">Name is required</span>
                                    </div>
                                  </div>
                                  <div class="form-group">
                                    <div>
                                      <label for="version">Version</label>
                                      <div v-if="name == 'micros'">
                                        <select class="form-control" id="" v-model="version">
                                          <option value="res">Res</option>
                                          <option value="sim1">Simphony 1</option>
                                          <option value="sim2">Simphony 2</option>
                                        </select>
                                      </div>
                                      <div v-else>
                                        <input type="text" class="form-control" v-model="version">
                                      </div>
                                    </div>
                              
                                  </div>

                                  <div class="form-group">
                                    <div>
                                      <label for="status">Status:</label>
                                      <select name="" v-model="status" @blur="v$.status.$touch" class="form-control mt-3">
                                        <option value="1"><strong><span class="active">Publish</span></strong> </option>
                                        <option value="0"><strong><span class="disabled">Unpublish</span></strong></option>
                                      </select>
                                    </div>
                                    <div class="input_error" v-if="v$.status.$error">
                                      <span v-if="v$.status.required.$invalid">Status is required</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="modal-footer">
                                  <button type="button" :id="`create_close_btn${config.id}`" class="btn btn-grey" data-dismiss="modal">Close</button>
                                  <button type="submit" class="btn btn-orange"><span v-if="creating"><i class="fa fa-spinner fa-spin "></i></span> Save</button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>                        
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div v-if="loading" class="loading-cover">
                  <div class="loader"> </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
import { useToast } from "vue-toastification";
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
const toast = useToast();
export default {
    data() {
        return {
            posConfigs: [],
            loading: false,
            responseData: null,
            name: '',
            status: '1',
            creating: false,
            updating: false,
            version:''

        }
    },
    setup() {
        return { v$: useVuelidate() }
    },
    mounted: async function () {
        this.getPosConfigs();
    },
    validations() {
        return {
            name: { required },
            status: { required }
        }
    },
    methods: {
        editConfig(config){
            this.name = config.name
            this.status = config.status
            this.version = config.version
        },
        async getPosConfigs() {
            this.loading = true;
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = `${this.dashBaseUrl}/pos-configs/${this.merchantID}`;
            this.axios.get(url, config).then((response) => {
                if (response.status === 200) {
                    this.loading = false
                    this.posConfigs = response.data.data.data
                }
            }).catch((error) => {
                this.loading = false;
                if (error.response.status === 401) {
                    this.$router.push({
                        name: 'login'
                    });
                }
            });
            // 
        },

        async createPosConfig(id='') {
            try {
                this.creating = true;
                const isFormCorrect = await this.v$.$validate()
                // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
                if (!isFormCorrect) {
                    this.creating = false;
                    return;
                }
                const url = `${this.dashBaseUrl}/create-pos-config/${this.merchantID}`;
                if(this.name == 'micros' && this.version == ''){
                  this.version = 'sim2'
                }
                const payload = {
                    'name': this.name,
                    'status': this.status,
                    'version': this.version
                }
                const config = {
                    headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
                };
                const res = await this.axios.post(url, payload, config);
                if (res.status == 201) {
                    this.getPosConfigs();
                    if(id !=''){
                      document.getElementById(`create_close_btn${id}`).click();
                    }else{
                      document.getElementById(`create_close_btn`).click();
                    }
                   
                    this.creating = false;
                    toast.success('pos config created successfully');
                }
            } catch (error) {
                this.creating = false;
                if (error.response.status === 400) {
                    let errormsgs = '';
                    for (let x = 0; x < error.response.data.errors.length; x++) {
                        errormsgs = error.response.data.errors[x] + ','
                    }
                    toast.error(errormsgs);
                    if(id !=''){
                      document.getElementById(`create_close_btn${id}`).click();
                    }else{
                      document.getElementById(`create_close_btn`).click();
                    }
                    // document.getElementById('btn_close' + id).click();
                } else if (error.response.status === 401) {
                    this.$router.push({
                        name: 'login'
                    });
                }
                // 
            }

        }
    },
    mixins: [ReusableDataMixins, ReusableFunctionMixins]
}
</script>
